


































































import Vue from "vue";
const VueUploadComponent = () => import("vue-upload-component");

export default Vue.extend({
  props: {
    "upload-text": String,
    imagesOnly: {
      type: Boolean,
      default: false,
    },
    maxFiles: {
      type: Number,
      default: null,
    },
    value: Object,
    enableImageEdit: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      files: [] as any[],
      error: null as string | null,
      authToken: localStorage.getItem("token"),
      //@ts-ignore
      apiHost: config.ApiHost
    };
  },
  components: {
    FileUpload: VueUploadComponent,
  },
  methods: {
    /**
     * Has changed
     * @@param  Object|undefined   newFile   Read only
     * @@param  Object|undefined   oldFile   Read only
     * @@return undefined
     */
    inputFile: function (newFile: any, oldFile: any) {
      console.log(newFile);
      console.log(oldFile);
      if (newFile.error) {
        this.files.splice(this.files.indexOf(newFile), 1);
        this.$swal({
          title: "Error",
          icon: "error",
          text: "Er is een onverwachte fout opgetreden"
        });
        return;
      }
      if (newFile && oldFile && !newFile.active && oldFile.active) {
        // Get response data
        console.log("response", newFile.response);
        var responseJson = JSON.parse(newFile.response)
        newFile.url = responseJson.contentUrl;
        newFile.contentUrl = responseJson.contentUrl;
        newFile.id = responseJson["@id"];
        //this.uploadedMediaItems.push(newFile.response.medium);
        var res = this.$emit("file-uploaded", responseJson);
        this.$emit("input", this.maxFiles == 1 ? this.files[0] : this.files);
        console.log(res);
        if (newFile.xhr) {
          //  Get the response status code
          console.log("status", newFile.xhr.status);
        }
      }
      // Automatic upload
      if (
        Boolean(newFile) !== Boolean(oldFile) ||
        oldFile.error !== newFile.error
      ) {
        // @ts-ignore
        if (!this.$refs.upload.active) {
          // @ts-ignore
          this.$refs.upload.active = true;
        }
      }
    },
    /**
     * Pretreatment
     * @@param  Object|undefined   newFile   Read and write
     * @@param  Object|undefined   oldFile   Read only
     * @@param  Function           prevent   Prevent changing
     * @@return undefined
     */
    inputFilter: function (newFile: any, oldFile: any, prevent: any) {
      var self = this;
      console.log(this.files.length > this.maxFiles);
      /*  if(this.files.length > this.maxFiles)
        return prevent() */
      if (newFile && !oldFile) {
        // Filter non-image file
        console.log(self.imagesOnly);
        if (
          this.imagesOnly &&
          !/\.(jpeg|jpe|jpg|gif|png|heic|heif)$/i.test(newFile.name)
        ) {
          console.log("error");
          this.error = "Only images are allowed (jpg, png, gif)";
          setTimeout(function () {
            self.error = null;
          }, 2000);
          return prevent();
        }
      }
      // Create a blob field
      /*newFile.blob = ''
                    let URL = window.URL || window.webkitURL
                    if (URL && URL.createObjectURL) {
                        newFile.blob = URL.createObjectURL(newFile.file)
                    }*/
    },
    async removeFile(file: any) {
      var res = await this.$swal({
        title: "Opgelet",
        showCancelButton: true,
        icon: "warning",
        text: "Ben je zeker dat je deze afbeelding wilt verwijderen?",
      });
      if (res.value) this.files.splice(this.files.indexOf(file), 1);
      this.$emit("file-removed", file);
    },
  },
  computed: {
    active: function (): boolean {
      // @ts-ignore
      return this.$refs.upload ? this.$refs.upload.active : false;
    },
  },
  mounted() {
    if(Array.isArray(this.value))
        this.files = this.value;
    else if(!this.value)
      this.files = []
    else
        this.files = [this.value]
  },
});
