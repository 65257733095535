import { IPagingOptions } from "@/interfaces/PagingOptions"
import { IPagingResult, PagingResult } from "@/interfaces/PagingResult"
import Api from "@/libs/Api"

class MockTransportHubRepository implements ITransportHubRepository {
  private locationTransportHubs = [
    {
      locationId: '38a52be4-9352-453e-af97-5c3b448652f3',
      transportHubId: '38a52be4-9352-453e-af97-5c3b448652f3'
    },
    {
      locationId: '38a52be4-9352-453e-af97-5c3b448652f3',
      transportHubId: '38a52be4-9352-453e-af97-5c3b448652f4'
    },
    {
      locationId: '38a52be4-9352-453e-af97-5c3b448652f3',
      transportHubId: '38a52be4-9352-453e-af97-5c3b448652f7'
    },
    {
      locationId: '38a52be4-9352-453e-af97-5c3b448652f3',
      transportHubId: '38a52be4-9352-453e-af97-5c3b448652e5'
    },
    {
      locationId: '38a52be4-9352-453e-af97-5c3b448652f3',
      transportHubId: '38a52be4-9352-453e-af97-5c3b448652f8'
    },
    {
      locationId: '38a52be4-9352-453e-af97-5c3b448652f3',
      transportHubId: '38a52be4-9352-453e-af97-5c3b448652e7'
    },
  ]

  private transportHubs: ITransportHub[] = [
    {
      id: '38a52be4-9352-453e-af97-5c3b448652f3',
      type: 'treinstation',
      name: 'Station Kortrijk',
      coordinates: {
        longitude: 50.797662599242166,
        latitude: 3.2768662871302516
      },
      lines: [
        {
          terminus: "Vichte",
          stops: [
            "Kortrijk",
            "Harelbeke",
            "Deerlijk",
            "Vichte",
            "Anzegem"
          ],
          minutesDelayed: 2,
          departureTime: "13:28",
          nextDeparture: "6 min",
          nextNextDeparture: "26 min"
        },
        {
          terminus: "Kuurne Sint-Pieter",
          stops: [
            "Kortrijk station",
            "Kuurne Sint-Pieter"
          ],
          minutesDelayed: 0,
          departureTime: "13:47",
          nextDeparture: "23 min",
          nextNextDeparture: "53 min"
        },
        {
          terminus: "Locatie X",
          stops: [
            "Halte 1",
            "Halte 2"
          ],
          minutesDelayed: -1,
          departureTime: "14:12",
          nextDeparture: "48 min",
          nextNextDeparture: "108 min"
        }
      ]
    },
    {
      id: '28a52be4-9352-453e-af97-5c3b448652f4',
      type: 'busstop',
      name: 'De lijn: Halte Barco',
      coordinates: {
        longitude: 50.797662599242166,
        latitude: 3.2768662871302516
      },
      lines: [
        {
          lineNumber: "12",
          color: 'purple',
          terminus: "Vichte",
          stops: [
            "Kortrijk",
            "Harelbeke",
            "Deerlijk",
            "Vichte",
            "Anzegem"
          ],
          minutesDelayed: 2,
          departureTime: "13:28",
          nextDeparture: "6 min",
          nextNextDeparture: "26 min"
        },
        {
          lineNumber: "40",
          color: "yellow",
          terminus: "Kuurne Sint-Pieter",
          stops: [
            "Kortrijk station",
            "Kuurne Sint-Pieter"
          ],
          minutesDelayed: 0,
          departureTime: "13:47",
          nextDeparture: "23 min",
          nextNextDeparture: "53 min"
        },
        {
          lineNumber: "512",
          color: "blue",
          terminus: "Locatie X",
          stops: [
            "Halte 1",
            "Halte 2"
          ],
          minutesDelayed: -1,
          departureTime: "14:12",
          nextDeparture: "48 min",
          nextNextDeparture: "108 min"
        }
      ]
    },
    {
      id: '38a52be4-9352-453e-af97-5c3b448652f4',
      type: 'busstop',
      name: 'De lijn: Halte Barco 2',
      coordinates: {
        longitude: 50.797662599242166,
        latitude: 3.2768662871302516
      },
      lines: [
        {
          lineNumber: "12",
          color: 'purple',
          terminus: "Vichte",
          stops: [
            "Kortrijk",
            "Harelbeke",
            "Deerlijk",
            "Vichte",
            "Anzegem"
          ],
          minutesDelayed: 2,
          departureTime: "13:28",
          nextDeparture: "6 min",
          nextNextDeparture: "26 min"
        },
        {
          lineNumber: "40",
          color: "yellow",
          terminus: "Kuurne Sint-Pieter",
          stops: [
            "Kortrijk station",
            "Kuurne Sint-Pieter"
          ],
          minutesDelayed: 0,
          departureTime: "13:47",
          nextDeparture: "23 min",
          nextNextDeparture: "53 min"
        },
        {
          lineNumber: "512",
          color: "blue",
          terminus: "Locatie X",
          stops: [
            "Halte 1",
            "Halte 2"
          ],
          minutesDelayed: -1,
          departureTime: "14:12",
          nextDeparture: "48 min",
          nextNextDeparture: "108 min"
        }
      ]
    },
    {
      id: '48a52be4-9352-453e-af97-5c3b448652f4',
      type: 'busstop',
      name: 'De lijn: Halte Barco 3',
      coordinates: {
        longitude: 50.797662599242166,
        latitude: 3.2768662871302516
      },
      lines: [
        {
          lineNumber: "12",
          color: 'purple',
          terminus: "Vichte",
          stops: [
            "Kortrijk",
            "Harelbeke",
            "Deerlijk",
            "Vichte",
            "Anzegem"
          ],
          minutesDelayed: 2,
          departureTime: "13:28",
          nextDeparture: "6 min",
          nextNextDeparture: "26 min"
        },
        {
          lineNumber: "40",
          color: "yellow",
          terminus: "Kuurne Sint-Pieter",
          stops: [
            "Kortrijk station",
            "Kuurne Sint-Pieter"
          ],
          minutesDelayed: 0,
          departureTime: "13:47",
          nextDeparture: "23 min",
          nextNextDeparture: "53 min"
        },
        {
          lineNumber: "512",
          color: "blue",
          terminus: "Locatie X",
          stops: [
            "Halte 1",
            "Halte 2"
          ],
          minutesDelayed: -1,
          departureTime: "14:12",
          nextDeparture: "48 min",
          nextNextDeparture: "108 min"
        }
      ]
    },
    {
      id: '58a52be4-9352-453e-af97-5c3b448652f7',
      type: 'busstop',
      name: 'De lijn: Halte Curate',
      coordinates: {
        longitude: 50.797662599242166,
        latitude: 3.2768662871302516
      },
      lines: []
    },
    {
      id: '38a52be4-9352-453e-af97-5c3b448652f5',
      type: 'bluebike',
      name: 'President Kennedylaan',
      bikesAvailable: 3,
      coordinates: {
        longitude: 50.797662599242166,
        latitude: 3.2768662871302516
      },
      lines: []
    },
    {
      id: '38a52be4-9352-453e-af97-5c3b448652e5',
      type: 'bluebike',
      name: 'AZ Groeninge',
      bikesAvailable: 5,
      coordinates: {
        longitude: 50.797662599242166,
        latitude: 3.2768662871302516
      },
      lines: []
    },
    {
      id: '38a52be4-9352-453e-af97-5c3b448652f8',
      type: 'donkeybike',
      name: 'XPO Kortrijk',
      bikesAvailable: 4,
      coordinates: {
        longitude: 50.797662599242166,
        latitude: 3.2768662871302516
      },
      lines: []
    },
    {
      id: '38a52be4-9352-453e-af97-5c3b448652e7',
      type: 'donkeybike',
      name: 'Barco',
      bikesAvailable: 5,
      coordinates: {
        longitude: 50.797662599242166,
        latitude: 3.2768662871302516
      },
      lines: []
    },
  ]

  public transportationTypeLabels = {
    'trainStation': 'Treinstation',
    'busStop': 'Bushalte',
    'monkeyBike': 'Donkey Bike',
    'blueBike': 'Blue Bike'
  }


  public async getAllTransportHubsInLocationRadius(locationId: string, type?: TransportationType): Promise<ITransportHub[]> {
    if (type)
      return this.transportHubs
        .filter(h => h.type == type)
        .filter(h => this.locationTransportHubs.some(lh => lh.transportHubId == h.id && lh.locationId == locationId))
    else
      return this.transportHubs
  }

  public async getAllTransportHubsInLocationRadiusGroupedByType(locationId: string): Promise<{ [type: string]: ITransportHub[] }> {
    const transportHubs = await this.getAllTransportHubsInLocationRadius(locationId);

    return transportHubs.reduce(function (rv: any, x: ITransportHub) {
      (rv[x['type']] = rv[x['type']] || []).push(x);
      return rv;
    }, {});
  }

  public async getAllTransportHubsInRadius(longitude: string, latitude: string, radius: string, hubType: string): Promise<ITransportHub[]> {
    return []
  }
}

export class ApiTransportHubRepository implements ITransportHubRepository {
  

  
  public transportationTypeLabels = {
    'treinstation': 'Treinstation',
    'busstop': 'Bushalte',
    'donkeybike': 'Monkey Bike',
    'bluebike': 'Blue Bike'
  }

  public static transportationTypes = [
    'treinstation',
    'busstop',
    'donkeybike',
    'bluebike'
  ]


  public async getAllTransportHubsInLocationRadius(locationId: string, type?: TransportationType): Promise<ITransportHub[]> {
    const res = await Api.getData("getAllTransportHubsInLocationRadius/" + locationId + "/" + type);
        
    return res.data["hydra:member"]
  }

  public async getAllTransportHubsInLocationRadiusGroupedByType(locationId: string): Promise<{ [type: string]: ITransportHub[] }> {
    const res = await Api.getData("getAllTransportHubsInLocationRadiusGroupedByType/" + locationId);
        
    return res.data.reduce(function (r:any, a: ITransportHub) {
      r[a.type] = r[a.type] || [];
      r[a.type].push(a);
      return r;
  }, Object.create(null));
  }

  public async getAllTransportHubsInRadius(longitude: string, latitude: string, radius: string, hubType: string): Promise<ITransportHub[]> {
    const res = await Api.getData("getAllTransportHubsInRadius/" + longitude + "/" + latitude + "/" + radius + "/" + hubType);
        
    return res.data
  }
}

export interface ITransportHub {
  id: string,
  name: string,
  coordinates: {
    longitude: number,
    latitude: number
  },
  type: TransportationType,
  lines:
  {
    lineNumber?: string,
    color?: string,
    terminus: string,
    stops: string[],
    minutesDelayed: number,
    departureTime: string,
    nextDeparture: string,
    nextNextDeparture: string
  }[],
  bikesAvailable?: number
}

type TransportationType = typeof ApiTransportHubRepository.transportationTypes[number]

export interface ITransportHubRepository {
  getAllTransportHubsInLocationRadius(locationId: string, type: TransportationType): Promise<ITransportHub[]>
  getAllTransportHubsInLocationRadiusGroupedByType(locationId: string): Promise<{ [type: string]: ITransportHub[] }>
  getAllTransportHubsInRadius(longitude: string, latitude: string, radius: string, hubType: string): Promise<ITransportHub[]>
}

export const transportHubRepo = new ApiTransportHubRepository();