



































































import Vue from "vue";
import "vue-good-table/dist/vue-good-table.css";
import { locationRepo, ILocation } from "@/repositories/LocationRepository";
import { IPagingOptions } from "@/interfaces/PagingOptions";

export default Vue.extend({
  props: {
    userId: String,
  },
  components: {
    "vue-good-table": require("vue-good-table").VueGoodTable,
  },
  data: function () {
    return {
      columns: [
        {
          label: "",
          field: "id",
          sortable: false,
          width: "20px",
          action: "edit",
        },
        {
          label: "",
          field: "id",
          sortable: false,
          width: "20px",
          action: "delete",
        },
        {
          label: "Naam",
          field: "name",
        },
        {
          label: "Gebruiker",
          action: "user",
          field: "id",
        }
      ],
      locations: [] as ILocation[],
      totalRecords: 0,
      serverParams: {
        page: 1,
        perPage: 10,
        sort: {
          field: "",
          type: "asc",
        },
        search: "",
      } as IPagingOptions,
      isLoading: false,
    };
  },
  methods: {
    editLocation: function (locationId: string) {
      this.$router.push({
        name: "adminLocationDetail",
        params: { id: locationId },
      });
    },
    async deleteLocation(id: string) {
      var res = await this.$swal({
        title: "Opgelet",
        showCancelButton: true,
        icon: "warning",
        text: "Ben je zeker dat je deze locatie wilt verwijderen?",
        confirmButtonColor: "#5945f3",
      });
      if (res.value) {
        var success = await locationRepo.deleteLocation(id);
        this.loadItems();
        if (success)
          this.$swal({
            icon: "success",
            toast: true,
            timer: 3000,
            timerProgressBar: true,
            title: "Locatie succesvol verwijderd",
            showConfirmButton: false,
            position: "top",
          });
      }
    },
    updateParams(newProps: any) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    onPageChange(params: any) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },
    onPerPageChange(params: any) {
      this.updateParams({ perPage: params.currentPerPage, page: 1 });
      this.loadItems();
    },
    onSortChange(params: any) {
      // if the sort parameters didn't change, nothing has to happen
      if (
        this.serverParams.sort.field == params[0].field &&
        this.serverParams.sort.type == params[0].type
      )
        return;
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
        page: 1,
      });
      this.loadItems();
    },
    setSortColumn(column: string) {
      //this.serverParams.sort.field = column
      this.onSortChange([{ field: column, type: this.serverParams.sort.type }]);
    },
    setSortType(type: string) {
      //this.serverParams.sort.field = column
      this.onSortChange([{ field: this.serverParams.sort.field, type: type }]);
    },
    async loadItems() {
      this.isLoading = true;
      try {
        if (this.userId) {
          const res = await locationRepo.getAllLocationsByUserId(this.userId);

          this.locations = res;
          this.totalRecords = res?.length ?? 0;
        } else {
          const res = await locationRepo.getAllLocations(this.serverParams);
          this.locations = res.items;
          this.totalRecords = res?.totalRecords ?? 0;
        }
      } catch (e) {
        console.log(e);
      }
      this.isLoading = false;
    },
  },
  mounted: async function () {
    this.loadItems();
  },
});
