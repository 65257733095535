export interface IPagingResult<T>{
    totalRecords: number,
    page: number,
    perPage: number,
    items: T[]
}

export class PagingResult<T> implements IPagingResult<T>{

    constructor(totalRecords : number, page : number, perPage: number, items: T[]){
        this.totalRecords = totalRecords
        this.page = page
        this.perPage = perPage
        this.items = items
    }

    totalRecords: number;
    page: number;
    perPage: number;
    items: T[];

}