






import Vue from "vue";
import locationOverview from "@/components/admin/LocationOverview.vue";

export default Vue.extend({
  props: {
    userId: String,
  },
  components: {
    locationOverview
  },
  data: function () {
    return {
    }
  },
  methods: {
    
  }
});
