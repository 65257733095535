import { IPagingOptions } from "@/interfaces/PagingOptions"
import { IPagingResult, PagingResult } from "@/interfaces/PagingResult"
import Api from "@/libs/Api"
import axios from "axios"

class MockUserRepository implements IUserRepository {
  private users: IUser[] = [
    {
      id: '38a52be4-9352-453e-af97-5c3b448652f0',
      firstName: 'Frank',
      lastName: 'Vercaemere',
      email: 'test@debugged.be',
      roles: []
    },
    {
      id: '38a52be4-9352-453e-af97-5c3b448652f1',
      firstName: 'Francine',
      lastName: 'Joost',
      email: 'francine.joost@test.be',
      roles: []
    },
    {
      id: '38a52be4-9352-453e-af97-5c3b448652f2',
      firstName: 'Jean-Paul',
      lastName: 'Citru',
      email: 'jp.citru@test.be',
      roles: []
    }
  ]

  public async getAllUsers(pagingOptions: IPagingOptions): Promise<IPagingResult<IUser>> {
    const nbPreceding = (pagingOptions.page - 1) * pagingOptions.perPage
    return new PagingResult(this.users.length, pagingOptions.page, pagingOptions.perPage,
      this.users.slice(nbPreceding, nbPreceding + pagingOptions.perPage)
    )
  }

  public async getUserById(id: string): Promise<IUser | null> {
    return this.users.find(u => u.id == id) || null
  }

  public async createUser(user: IUser): Promise<IUser> {
    user.id = this.generateUUID()
    this.users.push(user)
    return user;
  }

  private generateUUID(): string {
    let d = new Date().getTime();//Timestamp
    let d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = Math.random() * 16;//random number between 0 and 16
      if (d > 0) {//Use timestamp until depleted
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {//Use microseconds since page-load if supported
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }

  public async deleteUser(userId: string): Promise<boolean> {
    const user = await this.getUserById(userId)

    if (user) {
      this.users.splice(this.users.indexOf(user), 1)

      return true
    }

    return false
  }

  public async updateUser(user: IUser): Promise<void> {

    let existingUser = await this.getUserById(user.id)
    existingUser = user
  }

  public async login(loginDetails: {
    email: string
    password: string,
    rememberMe: boolean,
  }): Promise<any | null> {
    if (loginDetails.email == 'test@debugged.be' && loginDetails.password == 'test1234') {
      localStorage.setItem('user', JSON.stringify(this.users[0]))
      return {
        success: true,
        user: this.users[0]
      }
    }
    else {
      return {
        success: false,
        error: 'InvalidLoginCombo'
      }
    }
  }

  public async sendLoginDetails(userId: string): Promise<void> {
    return;
  }

  public async logout(): Promise<void> {
    localStorage.removeItem('user')
  }

  public async getLoggedInUserDetails(): Promise<IUser | null> {
    if (localStorage.getItem('user'))
      return JSON.parse(localStorage.getItem('user') as string)
    else
      return null
  }
}

class ApiUserRepository implements IUserRepository {
  public async getAllUsers(pagingOptions: IPagingOptions): Promise<IPagingResult<IUser>> {

    const res = await Api.getData("user/getAllUsers?page=" + pagingOptions.page + "&itemsPerPage=" + pagingOptions.perPage);

    for (const member of res.data["hydra:member"]) {
      member.id = member["@id"].substring(member["@id"].lastIndexOf("/") + 1, member["@id"].length)
      const split = member.lastName.split('|')
      member.lastName = split[0]
      member.company = split[1]
    }

    return new PagingResult(res.data["hydra:totalItems"], pagingOptions.page, pagingOptions.perPage,
      res.data["hydra:member"]
    )
  }

  public async getUserById(id: string): Promise<IUser | null> {
    const res = await Api.getData("user/getById/" + id);
    const split = res.data.lastName.split('|')
    res.data.lastName = split[0]
    res.data.company = split[1]
    res.data.id = res.data["@id"].substring(res.data["@id"].lastIndexOf("/") + 1, res.data["@id"].length)
    if (res.status == 404)
      return null

    return res.data
  }

  public async createUser(user: IUser): Promise<IUser> {

    // clone user object
    const userCopy = JSON.parse(JSON.stringify(user))
    userCopy.lastName = user.lastName + "|" + user.company
    const res = await Api.postData("user/create", userCopy)
    const split = res.data.lastName.split('|')
    res.data.lastName = split[0]
    res.data.company = split[1]
    return res.data
  }

  public async deleteUser(userId: string): Promise<boolean> {
    const res = await Api.deleteData("user/" + userId)

    if (res.status == 204)
      return true
    else return false
  }

  public async updateUser(user: IUser): Promise<void> {
    // clone user object
    const userCopy = JSON.parse(JSON.stringify(user))
    userCopy.lastName = user.lastName + "|" + user.company
    const locationIds = []
    //@ts-ignore
    for (const location of user.locations) {
      locationIds.push(location["@id"])
    }
    //@ts-ignore
    userCopy.locations = locationIds
    const res = await Api.putData("user/" + user.id, userCopy)

    return res.data
  }

  public async login(loginDetails: {
    email: string
    password: string,
    rememberMe: boolean,
  }): Promise<any | null> {
    try {
      //@ts-ignore
      const res = await axios.post(config.ApiUrl + "authentication_token", { email: loginDetails.email, password: loginDetails.password }, {
        //AxiosRequestConfig parameter
        headers: {
          "content-type": "application/json"
        }
      });

      if (res.data.token) {
        //@ts-ignore
        loginDetails.id = res.data.user_id
        localStorage.setItem("token", res.data.token)
        localStorage.setItem("user", JSON.stringify(loginDetails))
        return {
          success: true,
          user: loginDetails
        }
      } else {
        return {
          success: false,
          error: 'InvalidLoginCombo'
        }
      }
    } catch (error: any) {
      console.log(error.response);
      if (error.response.status == 405 || error.response.status == 401) {

        return {
          success: false,
          error: 'InvalidLoginCombo'
        }
      } else {
        throw (error);
      }
    }
  }

  public async requestPasswordConfirmation(email: string): Promise<void> {
    const res = await Api.postData("user/sendResetPasswordConfirmationEmail/" + email, {})
  }

  public async sendLoginDetails(userId: string): Promise<void> {
    const res = await Api.postData("user/sendResetPasswordEmail/" + userId, {})
  }

  public async logout(): Promise<void> {
    localStorage.removeItem('user')
  }

  public async getLoggedInUserDetails(): Promise<IUser | null> {
    if (localStorage.getItem('user')) {
      const user = JSON.parse(localStorage.getItem('user') as string)

      return await this.getUserById(user.id)
    }
    else
      return null
  }
}

export interface IUser {
  firstName: string,
  lastName: string,
  id: string,
  email: string,
  roles: string[],
  company?: string
}

export interface IUserRepository {
  getAllUsers: (pagingOptions: IPagingOptions) => Promise<IPagingResult<IUser>>,
  updateUser: (user: IUser) => Promise<void>,
  createUser: (user: IUser) => Promise<IUser>,
  getUserById: (id: string) => Promise<IUser | null>,
  deleteUser(userId: string): Promise<boolean>,
  login(loginDetails: {
    email: string
    password: string,
    rememberMe: boolean,
  }): Promise<any | null>
  logout(): Promise<void>
  sendLoginDetails(userId: string): Promise<void>
}

export const userRepo = new ApiUserRepository();