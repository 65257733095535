import Api from "@/libs/Api"

class MockSlideRepository implements ISlideRepository {
  public animationLabels = {
    'no-animation': 'Geen animatie',
    'jack-in-the-box': 'Duveltje-int-dooske',
    'elastic-swing': 'Elastische swing',
    'flash': 'Flitsen',
    'zoom-in': 'Inzoomen',
    'flip-in': 'Omdraaien',
    'roll-in': 'Rollen',
    'slide-down': 'Naar beneden schuiven',
    'slide-up': 'Naar boven schuiven',
    'slide-to-left': 'Naar links schuiven',
    'slide-to-right': 'Naar rechts schuiven',
    'bounce': 'Stuiteren',
    'bounce-in-down': 'Stuiteren naar beneden',
    'bounce-in-up': 'Stuiteren naar boven',
    'bounce-in-left': 'Stuiteren naar links',
    'bounce-in-right': 'Stuiteren naar rechts',
    'fade-in': 'Vervagen',
    'fade-in-down': 'Vervagen naar onder',
    'fade-in-up': 'Vervagen naar boven',
    'swing-in-left-bck': 'Zwaai naar links'
  }

  public templateLabels = {
    'textWithImageLeft': 'Afbeelding links naast tekst',
    'textWithImageRight': 'Afbeelding rechts naast tekst',
    'textWithBackground': 'Achtergrondafbeelding met tekst'
  }

  private slides: ISlide[] = [
    {
      id: '38a52be4-9352-453e-af97-5c3b448652f4',
      name: 'Test slide',
      title: 'Dit is een test slide',
      titleColor: '#666',
      titleAnimation: 'bounce-in-up',
      titleFontSize: 40,
      titleFontWeight: 'bold',
      titleAlignment: 'center',
      timeActive: 6,
      text: 'Dit is een beschrijving die als test werd ingevoegd',
      textColor: '#51C562',
      textAnimation: 'slide-down',
      textFontSize: 16,
      textFontWeight: 'normal',
      textAlignment: 'center',
      backgroundColor: '#DC2323',
      locationId: '38a52be4-9352-453e-af97-5c3b448652f3',
      templateType: 'textWithImageLeft',
      image: {
        id: '38a52be4-9352-453e-af97-5c3b448652f6',
        url: 'https://via.placeholder.com/150'
      }
    },
    {
      id: '38a52be4-9352-453e-af97-5c3b448652f5',
      name: 'Test slide 2',
      title: 'Dit is een test slide',
      titleColor: '#333',
      titleAnimation: 'slide-up',
      titleFontSize: 30,
      titleFontWeight: 'bold',
      titleAlignment: 'left',
      timeActive: 10,
      text: 'Dit is een beschrijving die als test werd ingevoegd',
      textColor: '',
      textAnimation: 'jack-in-the-box',
      textFontSize: 14,
      textFontWeight: 'normal',
      textAlignment: 'center',
      backgroundColor: '#ccccc',
      locationId: '38a52be4-9352-453e-af97-5c3b448652f3',
      templateType: 'textWithImageRight',
      image: {
        id: '38a52be4-9352-453e-af97-5c3b448652f6',
        url: 'https://via.placeholder.com/150'
      }
    },
  ]

  public async getAllSlidesByLocationId(locationId: string): Promise<ISlide[]> {
    const locationSlides = this.slides.filter(l => l.locationId == locationId)

    return locationSlides;
  }

  public async getSlideById(id: string): Promise<ISlide | undefined> {
    return this.slides.find(l => l.id == id)
  }

  public async createSlide(slide: ISlide): Promise<ISlide> {
    slide.id = this.generateUUID()
    this.slides.push(slide)
    return slide;
  }

  public async updateSlideOrder(locationId: string, slides: ISlide[]): Promise<void> {
    return;
  }

  private generateUUID(): string {
    let d = new Date().getTime();//Timestamp
    let d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = Math.random() * 16;//random number between 0 and 16
      if (d > 0) {//Use timestamp until depleted
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {//Use microseconds since page-load if supported
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }

  public async deleteSlide(slideId: string): Promise<boolean> {
    const slide = await this.getSlideById(slideId)

    if (slide) {
      this.slides.splice(this.slides.indexOf(slide), 1)

      return true
    }

    return false
  }

  public async updateSlide(slide: ISlide): Promise<void> {

    let existingSlide = await this.getSlideById(slide.id)
    existingSlide = slide
  }
}

class ApiSlideRepository implements ISlideRepository {
  public animationLabels = {
    'no-animation': 'Geen animatie',
    'jack-in-the-box': 'Duveltje-int-dooske',
    'elastic-swing': 'Elastische swing',
    'flash': 'Flitsen',
    'zoom-in': 'Inzoomen',
    'flip-in': 'Omdraaien',
    'roll-in': 'Rollen',
    'slide-down': 'Naar beneden schuiven',
    'slide-up': 'Naar boven schuiven',
    'slide-to-left': 'Naar links schuiven',
    'slide-to-right': 'Naar rechts schuiven',
    'bounce': 'Stuiteren',
    'bounce-in-down': 'Stuiteren naar beneden',
    'bounce-in-up': 'Stuiteren naar boven',
    'bounce-in-left': 'Stuiteren naar links',
    'bounce-in-right': 'Stuiteren naar rechts',
    'fade-in': 'Vervagen',
    'fade-in-down': 'Vervagen naar onder',
    'fade-in-up': 'Vervagen naar boven',
    'swing-in-left-bck': 'Zwaai naar links'
  }

  public templateLabels = {
    'textWithImageLeft': 'Afbeelding links naast tekst',
    'textWithImageRight': 'Afbeelding rechts naast tekst',
    'textWithBackground': 'Achtergrondafbeelding met tekst'
  }

  private slides: ISlide[] = [
    {
      id: '38a52be4-9352-453e-af97-5c3b448652f4',
      name: 'Test slide',
      title: 'Dit is een test slide',
      titleColor: '#666',
      titleAnimation: 'bounce-in-up',
      titleFontSize: 40,
      titleFontWeight: 'bold',
      titleAlignment: 'center',
      timeActive: 6,
      text: 'Dit is een beschrijving die als test werd ingevoegd',
      textColor: '#51C562',
      textAnimation: 'slide-down',
      textFontSize: 16,
      textFontWeight: 'normal',
      textAlignment: 'center',
      backgroundColor: '#DC2323',
      locationId: '38a52be4-9352-453e-af97-5c3b448652f3',
      templateType: 'textWithImageLeft',
      image: {
        id: '38a52be4-9352-453e-af97-5c3b448652f6',
        url: 'https://via.placeholder.com/150'
      }
    },
    {
      id: '38a52be4-9352-453e-af97-5c3b448652f5',
      name: 'Test slide 2',
      title: 'Dit is een test slide',
      titleColor: '#333',
      titleAnimation: 'slide-up',
      titleFontSize: 30,
      titleFontWeight: 'bold',
      titleAlignment: 'left',
      timeActive: 10,
      text: 'Dit is een beschrijving die als test werd ingevoegd',
      textColor: '',
      textAnimation: 'jack-in-the-box',
      textFontSize: 14,
      textFontWeight: 'normal',
      textAlignment: 'center',
      backgroundColor: '#ccccc',
      locationId: '38a52be4-9352-453e-af97-5c3b448652f3',
      templateType: 'textWithImageRight',
      image: {
        id: '38a52be4-9352-453e-af97-5c3b448652f6',
        url: 'https://via.placeholder.com/150'
      }
    },
  ]

  public async getAllSlidesByLocationId(locationId: string): Promise<ISlide[]> {
    const res = await Api.getPublicData("slide/getAllSlides?location=" + locationId);
    for(const slide of res.data["hydra:member"]){
      slide.id = slide["@id"].substring(slide["@id"].lastIndexOf('/') + 1, slide["@id"].length)
      const split = slide.name.split('|')
      slide.name = split[0]
      slide.order = split[1]
    }



    res.data["hydra:member"].sort((a : ISlide, b : ISlide) => {
      if(a.order == -1)
        return -1

      if(b.order == -1)
        return 1
      return ((a.order ?? 0) > (b.order ?? 0)) ? 1 : -1
    })
    return res.data["hydra:member"]
  }

  public async getSlideById(id: string): Promise<ISlide | undefined> {
    try{
      const res = await Api.getPublicData("slide/getById/" + id)
      res.data.id = res.data["@id"].substring(res.data["@id"].lastIndexOf('/') + 1, res.data["@id"].length)
      const split = res.data.name.split('|')
      res.data.name = split[0]
      res.data.order = split[1]
      return res.data
    }catch{
      return undefined
    }
  }

  public async createSlide(slide: ISlide): Promise<ISlide> {
    // input space to bypass server side validation
    slide.title = slide.title ? slide.title : " "
    slide.text = slide.text ? slide.text : " "
    slide.name = slide.name + "|-1";

    //@ts-ignore
    delete slide.id
    //@ts-ignore
    slide.location = slide.locationId

    //@ts-ignore
    slide.image = slide.image ? slide.image["id"].substring(slide.image["id"].lastIndexOf('/') + 1, slide.image["id"].length) : ""
    const res = await Api.postData("slide/create", slide)

    return res.data;
  }

  public async updateSlideOrder(locationId: string, slides: ISlide[]): Promise<void> {
    let i = 0
    for(const slide of slides){
      const split = slide.name.split('|')
      slide.order = i
      // clone slide object
      const slideCopy = JSON.parse(JSON.stringify(slide))
      slideCopy.name = split[0] + "|" + i++
      await this.updateSlide(slideCopy)
    }
  }

  public async deleteSlide(slideId: string): Promise<boolean> {
    const res = await Api.deleteData("slide/" + slideId)

    if(res.status == 204)
      return true
    else return false
  }

  public async updateSlide(slide: ISlide): Promise<void> {
     //@ts-ignore
     slide["@id"] = slide["@id"] ? slide["@id"].substring(slide["@id"].lastIndexOf('/') + 1, slide["@id"].length) : ""
     //@ts-ignore
     const image = slide.image && Array.isArray(slide.image) ? slide.image[0] : slide.image
    //@ts-ignore
    slide.image = image ? image.id.substring(image.id.lastIndexOf('/') + 1, image.length) : ""

    //@ts-ignore
    slide.location = slide.location ? slide.location.substring(slide.location.lastIndexOf('/') + 1, slide.location.length) : ""
    const name = slide.name
    slide.name = slide.name + "|" + slide.order;
    const res = await Api.putData("slide/" + slide.id, slide)
    slide.name = name
    return res.data
  }
}

export interface ISlide {
  id: string,
  title?: string,
  name: string,
  titleColor?: string,
  titleAnimation?: AnimationType,
  titleFontSize?: number,
  titleFontWeight?: string,
  titleAlignment?: string,
  timeActive: number
  text?: string,
  textColor?: string,
  textAnimation?: AnimationType,
  textFontSize?: number,
  textFontWeight?: string,
  textAlignment?: string,
  backgroundColor?: string,
  locationId: string,
  templateType: TemplateType,
  image?: {id: string, url: string}
  order?: number
}

type TemplateType = 'textWithImageLeft' | 'textWithImageRight' | 'textWithBackground'
type AnimationType = 'no-animation' | 'jack-in-the-box' | 'elastic-swing' | 'flash' | 'zoom-in' | 'flip-in' | 'roll-in' | 'slide-down' |
                      'slide-up' | 'slide-to-left' | 'slide-to-right' | 'bounce' | 'bounce-in-down' | 'bounce-in-up' | 'bounce-in-left' |
                      'bounce-in-right' | 'fade-in' | 'fade-in-down' | 'fade-in-up' | 'swing-in-left-bck';

export interface ISlideRepository {
  getAllSlidesByLocationId: (locationId: string) => Promise<ISlide[]>,
  updateSlide: (slide: ISlide) => Promise<void>,
  createSlide: (slide: ISlide) => Promise<ISlide>,
  getSlideById: (id: string) => Promise<ISlide | undefined>,
  deleteSlide(slideId: string): Promise<boolean>,
  updateSlideOrder(locationId: string, slides: ISlide[]): Promise<void>
}

export const slideRepo = new ApiSlideRepository();
