import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/admin/Login.vue'
import AdminLayout from '../views/admin/Layout.vue'
import AdminHome from '../views/admin/Home.vue'
import AdminUserManagement from '../views/admin/user/UserManagement.vue'
import AdminUserDetail from '../views/admin/user/Detail.vue'
import AdminLocationDetail from '../views/admin/location/Detail.vue'
import AdminLocationOverview from '../views/admin/location/Overview.vue'
import AdminSlideOverview from '../views/admin/slide/Overview.vue'
import PickLocation from '../views/PickLocation.vue'
import { userRepo } from '@/repositories/UserRepository'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: { requiresAuth: false }
  },
  {
    path: '/admin/login',
    name: 'login',
    component: Login,
    meta: { requiresAuth: false }
  },
  {
    path: '/pick-location',
    name: 'pickLocation',
    component: PickLocation,
    meta: { requiresAuth: true }
  },
  {
    path: '/admin',
    component: AdminLayout,
    meta: { requiresAuth: true },
    children:[
      {
        path: '',
        component: AdminHome
      },
      {
        name: 'adminUserManagement',
        path: '/admin/user',
        component: AdminUserManagement
      },
      {
        name: 'adminUserDetail',
        path: '/admin/user/:id',
        component: AdminUserDetail
      },
      {
        name: 'adminLocationOverview',
        path: '/admin/location',
        component: AdminLocationOverview
      },
      {
        name: 'adminLocationDetail',
        path: '/admin/location/:id',
        component: AdminLocationDetail
      },
      {
        name: 'adminSlideOverview',
        path: '/admin/slides/:locationId',
        component: AdminSlideOverview
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (!to.matched.some(record => record.meta.requiresAuth))
      return next()

  if (await userRepo.getLoggedInUserDetails() == null) {
      next({
          path: '/admin/login',
          params: { nextUrl: to.fullPath },
          query: {redir : to.fullPath }
      })
      return;
  }
  next()
});

export default router
