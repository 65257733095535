import { IPagingOptions } from "@/interfaces/PagingOptions"
import { IPagingResult, PagingResult } from "@/interfaces/PagingResult"
import Api from "@/libs/Api"

class MockLocationRepository implements ILocationRepository {
  private locations: ILocation[] = [
    {
      id: '38a52be4-9352-453e-af97-5c3b448652f3',
      userId: '38a52be4-9352-453e-af97-5c3b448652f0',
      name: 'Barco Kortrijk',
      longitude: "50.797662599242166",
      latitude: "50.797662599242166",
      radius: "50",
      linkedTransportHubIds: ['38a52be4-9352-453e-af97-5c3b448652f3', "38a52be4-9352-453e-af97-5c3b448652f3",
        "48a52be4-9352-453e-af97-5c3b448652f4", "38a52be4-9352-453e-af97-5c3b448652f4", "38a52be4-9352-453e-af97-5c3b448652f5",
        "38a52be4-9352-453e-af97-5c3b448652f6", "38a52be4-9352-453e-af97-5c3b448652f7",
        "38a52be4-9352-453e-af97-5c3b448652e7", "38a52be4-9352-453e-af97-5c3b448652e5",
        "38a52be4-9352-453e-af97-5c3b448652f7", '38a52be4-9352-453e-af97-5c3b448652e7', "28a52be4-9352-453e-af97-5c3b448652f4",
        "58a52be4-9352-453e-af97-5c3b448652f7", '38a52be4-9352-453e-af97-5c3b448652f8'],
      notifications: [
        "Werken aan oudenaarsesteenweg",
        "Nieuwe uurregeling treinen vanaf 22/01",
        "Cras a felis sit amet nisl pretium aliquet",
        "Nunc sodales laoreet eros vel molestie.",
        "Pellentesque ullamcorper urna ante, posuere lobortis nunc faucibus id.",
        "Donec sed lacus tincidunt magna lobortis iaculis quis at augue."
      ],
      transporthubs: [],
      transportHubObjects: [],
      slides: [],
      mapConfig: '{"version":"1.0","basemapType":"Topographic","basemapOpacity":0.6,"initialCenter":[50.82901,3.267789],"zoomStep":0.25,"mapHeight":"100","initialZoomLevel":12,"minimumZoomLevel":"10.9","maximumZoomLevel":19,"services":[{"name":"Parkings","serviceUrl":"https://www.govmaps.eu/arcgis/rest/services/PARKO/PARKO_ONLINE/MapServer","layers":[{"id":6,"name":"Cambio","isVisibleByDefault":true,"showDisplayName":true},{"id":7,"name":"Laadpalen","isVisibleByDefault":true,"showDisplayName":true},{"id":10,"name":"Parkings","isVisibleByDefault":true,"showDisplayName":true}]},{"name":"DeLijn","serviceUrl":"https://www.govmaps.eu/arcgis/rest/services/ICL/ICL_PROJECT_SSNHW_DeLijn/MapServer","layers":[{"id":0,"name":"Halte","isVisibleByDefault":true,"showDisplayName":true}]}]}'
    },
    {
      id: '38a52be4-9352-453e-af97-5c3b448652f4',
      userId: '38a52be4-9352-453e-af97-5c3b448652f0',
      name: 'Barco Kuurne',
      longitude: "50.797662599242166",
      latitude: "50.797662599242166",
      radius: "20",
      linkedTransportHubIds: [],
      transporthubs: [],
      transportHubObjects: [],
      slides: [],
      notifications: [],
      mapConfig: '{"version":"1.0","basemapType":"Topographic","basemapOpacity":0.6,"initialCenter":[50.82901,3.267789],"zoomStep":0.25,"mapHeight":"600","initialZoomLevel":15,"minimumZoomLevel":"10.9","maximumZoomLevel":19,"services":[{"name":"Parkings","serviceUrl":"https://www.govmaps.eu/arcgis/rest/services/PARKO/PARKO_ONLINE/MapServer","layers":[{"id":6,"name":"Cambio","isVisibleByDefault":true,"showDisplayName":true},{"id":7,"name":"Laadpalen","isVisibleByDefault":true,"showDisplayName":true},{"id":10,"name":"Parkings","isVisibleByDefault":true,"showDisplayName":true}]},{"name":"DeLijn","serviceUrl":"https://www.govmaps.eu/arcgis/rest/services/ICL/ICL_PROJECT_SSNHW_DeLijn/MapServer","layers":[{"id":0,"name":"Halte","isVisibleByDefault":true,"showDisplayName":true}]}]}'
    }
  ]

  public async getAllLocations(pagingOptions: IPagingOptions): Promise<IPagingResult<ILocation>> {
    const nbPreceding = (pagingOptions.page - 1) * pagingOptions.perPage
    return new PagingResult(this.locations.length, pagingOptions.page, pagingOptions.perPage,
      this.locations.slice(nbPreceding, nbPreceding + pagingOptions.perPage)
    )
  }

  public async getAllPagedLocationsByUserId(userId: string, pagingOptions: IPagingOptions): Promise<IPagingResult<ILocation>> {
    const userLocations = this.locations.filter(l => l.userId == userId)
    const nbPreceding = (pagingOptions.page - 1) * pagingOptions.perPage
    return new PagingResult(userLocations.length, pagingOptions.page, pagingOptions.perPage,
      userLocations.slice(nbPreceding, nbPreceding + pagingOptions.perPage)
    )
  }

  public async getAllLocationsByUserId(userId: string): Promise<ILocation[]> {
    const userLocations = this.locations.filter(l => l.userId == userId)

    return userLocations
  }

  public async getLocationById(id: string): Promise<ILocation | undefined> {
    return this.locations.find(l => l.id == id)
  }

  public async createLocation(location: ILocation): Promise<ILocation> {
    location.id = this.generateUUID()
    this.locations.push(location)
    return location;
  }

  private generateUUID(): string {
    let d = new Date().getTime();//Timestamp
    let d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = Math.random() * 16;//random number between 0 and 16
      if (d > 0) {//Use timestamp until depleted
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {//Use microseconds since page-load if supported
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }

  public async deleteLocation(locationId: string): Promise<boolean> {
    const location = await this.getLocationById(locationId)

    if (location) {
      this.locations.splice(this.locations.indexOf(location), 1)

      return true
    }

    return false
  }

  public async updateLocation(location: ILocation): Promise<void> {

    //let existingLocation = await this.getLocationById(location.id)
    //existingLocation = location
  }
}

class ApiLocationRepository implements ILocationRepository {

  public async getAllLocations(pagingOptions: IPagingOptions): Promise<IPagingResult<ILocation>> {
    const res = await Api.getData("location/getAllLocations?page=" + pagingOptions.page + "&itemsPerPage=" + pagingOptions.perPage);
    for(const location of res.data["hydra:member"]){
      //@ts-ignore
      const split = location.Users.length > 0 ? location.Users[0].lastName.split('|') : null
      if(split){
        //@ts-ignore
        location.Users[0].lastName = split[0]
        //@ts-ignore
        location.Users[0].company = split[1]
      }
    }
    return new PagingResult(res.data["hydra:totalItems"], pagingOptions.page, pagingOptions.perPage,
      res.data["hydra:member"]
    )
  }

  public async getAllPagedLocationsByUserId(userId: string, pagingOptions: IPagingOptions): Promise<IPagingResult<ILocation>> {
    return [] as any;
  }

  public async getAllLocationsByUserId(userId: string): Promise<ILocation[]> {
    const res = await Api.getData("users/" + userId + "/locations")
    for(const location of res.data["hydra:member"]){
      //@ts-ignore
      const split = location.Users[0].lastName.split('|')
      //@ts-ignore
      location.Users[0].lastName = split[0]
      //@ts-ignore
      location.Users[0].company = split[1]
    }
    return res.data["hydra:member"]
  }

  public async getLocationById(id: string): Promise<ILocation | undefined> {
    try {
      const res = await Api.getPublicData("location/getById/" + id)
      const transporthubObjects = []
      for (const transportHub of res.data.transporthubs) {
        transportHub.bikesAvailable = transportHub.data.bikesAvailable
        transportHub.lines = transportHub.data.lines
        if (Array.isArray(transportHub.lines)) {
          if (transportHub.type == 'treinstation') {
            // find the next departure and next next departure for each train destination
            for (const line of transportHub.lines) {
              const nextLine = transportHub.lines.find((l: any) => transportHub.lines.indexOf(l) > transportHub.lines.indexOf(line) && l.destination == line.destination && l != line)

              line.nextDeparture = nextLine?.departure

              line.nextNextDeparture = transportHub.lines.find((l: any) => transportHub.lines.indexOf(l) > transportHub.lines.indexOf(line)
                      && l.destination == line.destination
                      && l != line
                      && l != nextLine)?.departure
              // seconds to minutes
              line.minutesDelayed = Math.floor(line.minutesDelayed / 60)
            }
          }

          if (transportHub.type == 'busstop') {
            const groupedLines = transportHub.lines.reduce(function (r: any, a: any) {
              r[a.destination + a.lineNumber] = r[a.destination + a.lineNumber] || [];
              r[a.destination + a.lineNumber].push(a);
              return r;
            }, Object.create(null));
            const lines = []
            for (const lineGroup of Object.values(groupedLines) as any[]) {
              lineGroup[0].departure = (new Date(lineGroup[0].departure)).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
              lineGroup[0].nextDeparture = lineGroup[1]?.departure
                ? (new Date(lineGroup[1]?.departure)).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
                : "";
              lineGroup[0].nextNextDeparture = lineGroup[2]?.departure
                ? (new Date(lineGroup[2]?.departure)).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
                : "";

              lines.push(lineGroup[0])
            }

            transportHub.lines = lines;
          }
        }
        transporthubObjects.push(transportHub)
      }

      res.data.transporthubs = res.data.transporthubs.map((t: any) => t.id)
      res.data.transportHubObjects = transporthubObjects
      return res.data

    } catch (e) {
      return undefined;
    }
  }

  public async createLocation(location: ILocation): Promise<ILocation> {

    // @ts-ignore
    location.Users = [location.userId]

    const res = await Api.postData("location/create", location)

    return res.data;
  }

  public async deleteLocation(locationId: string): Promise<boolean> {
    const res = await Api.deleteData("location/" + locationId)

    if (res.status == 204)
      return true
    else return false
  }

  public async updateLocation(location: ILocation): Promise<void> {
    const userIds = []
    //@ts-ignore
    for (const user of location.Users) {
      if (user["@id"])
        userIds.push(user["@id"].substring(user["@id"].lastIndexOf('/') + 1, user["@id"].length))
    }
    //@ts-ignore
    location.Users = userIds.length > 0 ? userIds : location.Users

    for (let i = 0; i < location.slides.length; i++) {
      location.slides[i] = location.slides[i].substring(location.slides[i].lastIndexOf('/') + 1, location.slides[i].length)
    }
    const res = await Api.putData("location/" + location.id, location)

    return res.data
  }
}

export interface ILocation {
  id: string,
  name: string,
  longitude: string,
  latitude: string
  radius: string,
  userId: string,
  linkedTransportHubIds: string[],
  notifications: string[],
  mapConfig: string,
  transporthubs: any[]
  transportHubObjects: any[]
  slides: any[]
}

export interface ILocationRepository {
  getAllPagedLocationsByUserId: (userId: string, pagingOptions: IPagingOptions) => Promise<IPagingResult<ILocation>>,
  getAllLocationsByUserId: (userId: string) => Promise<ILocation[]>,
  updateLocation: (location: ILocation) => Promise<void>,
  createLocation: (location: ILocation) => Promise<ILocation>,
  getLocationById: (id: string) => Promise<ILocation | undefined>,
  deleteLocation(locationId: string): Promise<boolean>
}

export const locationRepo = new ApiLocationRepository();
