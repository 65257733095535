var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-4"},[_c('div',{staticClass:"flex align-center space-between"},[_c('h2',[_vm._v("Gebruikers")]),_c('b-button',{staticClass:"mb-3",attrs:{"variant":"primary","to":{ name: 'adminUserDetail', params: { id: 'new' } }}},[_c('font-awesome-icon',{attrs:{"icon":"plus"}}),_vm._v(" Gebruiker aanmaken ")],1)],1),_c('div',{staticClass:"mt-3"},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.users,"mode":"remote","totalRows":_vm.totalRecords,"isLoading":_vm.isLoading,"pagination-options":{
        enabled: true,
        perPage: _vm.serverParams.perPage,
        dropdownAllowAll: false,
        setCurrentPage: _vm.serverParams.page,
      },"sort-options":{
        enabled: true,
        initialSortBy: _vm.serverParams.sort,
      }},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-per-page-change":_vm.onPerPageChange,"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event}},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.action == 'edit')?_c('span',{on:{"click":function($event){$event.stopPropagation();return _vm.editUser(props.row.id)}}},[_c('b-button',{attrs:{"variant":"primary"}},[_c('font-awesome-icon',{attrs:{"icon":"pencil-alt"}})],1)],1):(props.column.action == 'delete')?_c('span',[_c('b-button',{attrs:{"variant":"danger"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteUser(props.row.id)}}},[_c('font-awesome-icon',{attrs:{"icon":"trash"}})],1)],1):_vm._e()]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }