
import Vue, { CreateElement, VNode } from "vue";
import "gridstack/dist/gridstack.min.css";

export default Vue.extend({
  data() {
    return {
      activeIndex: 0,
      timeOutId: -1
    };
  },
  computed: {
    slides: function (): any[] {
      // All slides must be children of the `SliderSlides` component.
      return (
        this.$children?.find((x: any) => x.$options.name === "SliderSlides")
          ?.$children ?? []
      );
    },
    slidesCount(): number {
      return this.slides.length;
    },
    nextIndex(): number {
      const nextIndex = this.activeIndex + 1;

      return nextIndex <= this.slidesCount - 1 ? nextIndex : 0;
    },
    prevIndex(): number {
      const prevIndex = this.activeIndex - 1;

      return prevIndex >= 0 ? prevIndex : this.slidesCount - 1;
    },
  },
  mounted() {
    this.timeOutId = setTimeout(() => {
      // Immediately activate the first slide.
      
      this.goToIndex(this.activeIndex);
      console.log(this.slides[this.activeIndex]);
      this.timeOutId = setTimeout(() => {
        this.goToNextSlide();
      }, this.slides[this.activeIndex].$props?.timeActive ?? 5000);
    }, 100);
  },
  methods: {
    goToNextSlide() {
      if(this.activeIndex + 1 == this.slidesCount)
        this.$emit('loop-end')

      this.goToIndex((this.activeIndex + 1) % this.slidesCount);

      setTimeout(() => {
        this.goToNextSlide();
      }, this.slides[this.activeIndex].$props?.timeActive ?? 5000);
    },
    goToIndex(index: number) {
      
      // Find out the direction we're moving.
      // This is useful for animations.
      const direction = index > this.activeIndex ? "left" : "right";
      // Call the `hide()` method on the currently
      // active `SliderSlide` component.
      this.slides[this.activeIndex].hide(direction);
      // Call the `show()` method on the `SliderSlide`
      // component with the correspondign index.
      this.slides[index].show(direction);
      this.activeIndex = index;
    },
    next() {
      this.goToIndex(this.nextIndex);
    },
    prev() {
      this.goToIndex(this.prevIndex);
    },
  },
  render(h: CreateElement): VNode {
    return h(
      "div",
      this.$scopedSlots.default!({
        // Data
        activeIndex: this.activeIndex,
        // Methods
        goToIndex: this.goToIndex,
        next: this.next,
        prev: this.prev,
      })
    );
  },
  destroyed: function(){
    clearTimeout(this.timeOutId)
  }
});
