







































































import Vue from "vue";
import { email, required } from "vuelidate/lib/validators";
import { userRepo } from "@/repositories/UserRepository";

export default Vue.extend({
  data: function () {
    return {
      form: {
        email: "",
        password: "",
        rememberMe: false,
      },
      loading: false,
      formError: false,
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    },
  },
  methods: {
    doLogin: async function () {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;
        var res = await userRepo.login(this.form);
        this.formError = !res.success;
        console.log(this.$route.query["redir"])
        if (res.success) {          
          if (this.$route.query["redir"])
            this.$router.push(decodeURI(this.$route.query["redir"] as string));
          else this.$router.push({ name: "home" });
        }
        this.loading = false;
      }
    },
  },
});
