

























































































































































































































































































































































































































































































import Vue from "vue";
import "gridstack/dist/gridstack.min.css";
import { GridStack } from "gridstack";
// THEN to get HTML5 drag&drop
import "gridstack/dist/h5/gridstack-dd-native";
import { ILocation, locationRepo } from "@/repositories/LocationRepository";
import { ITransportHub } from "@/repositories/TransportHubRepository";
import SliderFrame from "@/components/SliderFrame.vue";
import SliderSlide from "@/components/SliderSlide.vue";
import SliderSlides from "@/components/SliderSlides.vue";
import { ISlide, slideRepo } from "@/repositories/SlideRepository";
export default Vue.extend({
  components: {
    SliderFrame,
    SliderSlide,
    SliderSlides,
  },
  data: function () {
    return {
      items: [] as any[],
      grid: null as GridStack | null,
      currentTime: new Date(),
      currentLocation: undefined as ILocation | undefined,
      transportHubsByType: {},
      slides: [] as ISlide[],
      showSlides: false,
      stopsPerHubBlock: {
        busstop: 3,
        treinstation: 1,
        donkeybike: 2,
        bluebike: 2,
      },
      timeActiveDashboard: 40,
      hubsToReset: [] as number[],
      hubBlockTime: 10000,
      savedLayout: null as any,
      //@ts-ignore
      apiUrl: config.ApiHost,
      locationRenewalInterval: -1,
    };
  },
  methods: {
    serializeGrid: function () {
      var data = Array.prototype.slice
        .call(document.querySelectorAll(".grid-stack > .grid-stack-item"))
        .map(function (el) {
          return {
            x: el.getAttribute("gs-x"),
            y: el.getAttribute("gs-y"),
            width: el.getAttribute("gs-w"),
            height: el.getAttribute("gs-h"),
          };
        });
      localStorage.setItem(
        "gridLayout-" + this.currentLocation?.id,
        JSON.stringify(data)
      );
    },
    initCurrentLocation: async function () {
      if (this.$route.query.locationId) {
        this.currentLocation = await locationRepo.getLocationById(
          this.$route.query.locationId as string
        );
      } else if (localStorage.getItem("currentLocationId")) {
        var locationId = JSON.parse(
          localStorage.getItem("currentLocationId") as string
        );
        console.log(locationId)
        this.currentLocation = await locationRepo.getLocationById(locationId);
        console.log(this.currentLocation)
      } else {
        this.$router.push({ name: "pickLocation" });
      }

      if (!this.currentLocation) this.$router.push({ name: "pickLocation" });

      this.transportHubsByType =
        this.currentLocation?.transportHubObjects.reduce(function (
          r: any,
          a: ITransportHub
        ) {
          r[a.type] = r[a.type] || [];
          r[a.type].push(a);
          return r;
        },
        Object.create(null));
      this.$forceUpdate();
    },
    toggleSlideMode() {
      // don't toggle if there are no slides
      if (this.slides.length == 0) return;

      if (this.showSlides) {
        this.showSlides = false;
        this.initCurrentLocation();
        this.initGrid();

        this.locationRenewalInterval = setInterval(async () => {
          await this.initCurrentLocation();
        }, this.hubBlockTime);
        setTimeout(() => {
          this.toggleSlideMode();
        }, this.timeActiveDashboard * 1000);
      } else {
        if (this.locationRenewalInterval >= 0)
          clearInterval(this.locationRenewalInterval);

        this.showSlides = true;
      }
    },
    initGrid() {
      this.savedLayout = localStorage.getItem(
        "gridLayout-" + this.currentLocation?.id
      )
        ? JSON.parse(
            localStorage.getItem("gridLayout-" + this.currentLocation?.id)!
          )
        : null;
      setTimeout(() => {
        if (this.savedLayout) {
          try {
            var items = document.querySelectorAll(".grid-stack-item"); //An item is now a grid-stack-item
            var key = 0;
            for (var el of items) {
              //Foreach item, i'll set the attributs as saved

              el.setAttribute("gs-x", this.savedLayout[key].x);
              el.setAttribute("gs-y", this.savedLayout[key].y);
              el.setAttribute("gs-h", this.savedLayout[key].height);
              el.setAttribute("gs-w", this.savedLayout[key].width);
              key++;
            }
          } catch (e) {
            localStorage.removeItem("gridLayout");
          }
        }
        this.grid = GridStack.init({
          column: 5,
          cellHeight: 7.9,
          cellHeightUnit: "vh",
          maxRow: 12,
        });
        this.grid.on("change", () => this.serializeGrid());
      }, 100);
    },
    initStopsPerBlock: function () {
      if (localStorage.getItem("stopsPerHubBlock")) {
        const storedStopsPerHubBlock = JSON.parse(
          localStorage.getItem("stopsPerHubBlock")!
        );

        this.stopsPerHubBlock.busstop =
          storedStopsPerHubBlock.busstop ?? this.stopsPerHubBlock.busstop;
        this.stopsPerHubBlock.treinstation =
          storedStopsPerHubBlock.treinstation ??
          this.stopsPerHubBlock.treinstation;
        this.stopsPerHubBlock.bluebike =
          storedStopsPerHubBlock.bluebike ?? this.stopsPerHubBlock.bluebike;
        this.stopsPerHubBlock.donkeybike =
          storedStopsPerHubBlock.donkeybike ?? this.stopsPerHubBlock.donkeybike;
      }
    },
  },
  mounted: async function () {
    this.initStopsPerBlock();

    await this.initCurrentLocation();
    this.locationRenewalInterval = setInterval(async () => {
      await this.initCurrentLocation();
    }, this.hubBlockTime);

    this.slides = await slideRepo.getAllSlidesByLocationId(
      this.currentLocation!.id
    );

    if (localStorage.getItem("timeActiveDashboard")) {
      this.timeActiveDashboard = parseInt(
        JSON.parse(localStorage.getItem("timeActiveDashboard")!)
      );
    }

    this.initGrid();
    setTimeout(() => {
      this.toggleSlideMode();
    }, this.timeActiveDashboard * 1000);

    setInterval(() => {
      this.currentTime = new Date();
    }, 1000);

    // eslint-disable-next-line no-undef
    const triggers = Array.from(document.getElementsByClassName('timetable') as HTMLCollectionOf<HTMLElement>);

    triggers.forEach((trigger, key) => {
      window.setInterval(() => {
        this.hubsToReset.push(key);
      }, this.hubBlockTime);

      window.setInterval(() => {
        if (this.hubsToReset.length > 0 && this.hubsToReset.includes(key)) {
          //@ts-ignore
          trigger.style['scroll-behavior'] = 'revert'; // @todo find better smooth scroll solution
          trigger.scrollTop = 0;
          //@ts-ignore
          trigger.style['scroll-behavior'] = 'smooth';
          this.hubsToReset = this.hubsToReset.filter(item => item !== key)
        } else {
          trigger.scrollTop = trigger.scrollTop + 3;
        }

      }, this.hubBlockTime / 100);
    })
  },
  created: function () {},
});
