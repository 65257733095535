































































































































import Vue from "vue";
import { userRepo, IUser } from "@/repositories/UserRepository";
import { email, required, requiredIf } from "vuelidate/lib/validators";
import locationOverview from "@/components/admin/LocationOverview.vue";

export default Vue.extend({
  props: {
    msg: String,
  },
  components: {
    locationOverview,
  },
  data: function () {
    return {
      user: {} as IUser,
      editMode: false,
    };
  },
  validations: {
    user: {
      firstName: {
        required,
      },
      lastName: {
        required,
      },
      email: {
        required,
        email,
      },
      password: {
        required: requiredIf(function () : boolean {
          // @ts-ignore
          return !this.editMode;
        }),
      },
      roles: {
        required,
      },
    },
  },
  methods: {
    saveUser: async function () {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      if (this.editMode) await userRepo.updateUser(this.user);
      else this.user = await userRepo.createUser(this.user);

      this.editMode = true;
      this.$router.replace({
        name: "adminUserDetail",
        params: { id: this.user.id },
      });

      this.$swal({
        icon: "success",
        toast: true,
        timer: 3000,
        timerProgressBar: true,
        title: "Gebruiker succesvol opgeslagen",
        showConfirmButton: false,
        position: "top",
      });
    },
    sendLoginDetails: async function () {
      if (this.$v.$invalid) return;

      var res = await this.$swal({
        title: "Opgelet",
        showCancelButton: true,
        icon: "warning",
        text: "Door deze actie krijgt de gebruiker automatisch een nieuw wachtwoord toegekend. Wil je doorgaan?",
        confirmButtonColor: "#5945f3",
      });

      if (res.value) {
        await userRepo.sendLoginDetails(this.user.id);
        this.$swal({
          icon: "success",
          toast: true,
          timer: 3000,
          timerProgressBar: true,
          title: "Logingegevens succesvol verzonden",
          showConfirmButton: false,
          position: "top",
        });
      }
    },
    cancel() {
      this.$router.go(-1);
    },
  },
  mounted: async function () {
    if (this.$route.params.id != "new") {
      this.user =
        (await userRepo.getUserById(this.$route.params.id)) ?? ({} as IUser);
      this.editMode = true;
    }
  },
});
