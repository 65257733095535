

















































import Vue from "vue";
import {  required } from "vuelidate/lib/validators";
import { userRepo } from "@/repositories/UserRepository";
import { ILocation, locationRepo } from "@/repositories/LocationRepository";

export default Vue.extend({
  name: "HelloWorld",
  data: function () {
    return {
      form: {
        currentLocation: null as null | ILocation,
      },
      loading: false,
      formError: false,
      locations: [] as ILocation[],
    };
  },
  validations: {
    form: {
      currentLocation: {
        required,
      },
    },
  },
  methods: {
    pickLocation: async function () {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;

        localStorage.setItem(
          "currentLocationId",
          JSON.stringify(this.form.currentLocation?.id)
        );

        if (this.$route.query["redir"])
          this.$router.push(this.$route.query["redir"] as string);
        else this.$router.push({ name: "home" });

        this.loading = false;
      }
    },
  },
  created: async function () {
    this.locations = await locationRepo.getAllLocationsByUserId(
      (await userRepo.getLoggedInUserDetails())?.id ?? ""
    );

    if (this.locations.length == 1) {
      localStorage.setItem(
        "currentLocationId",
        JSON.stringify(this.locations[0].id)
      );

      if (this.$route.query["redir"])
        this.$router.push(this.$route.query["redir"] as string);
      else this.$router.push({ name: "home" });
    }
  },
});
