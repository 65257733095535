<template>
  <transition :name="transition">
    <div v-show="active" class="SliderSlide">
      <slot />
    </div>
  </transition>
</template>

<script>
export default {
  name: "SliderSlide",
  props: {
    timeActive: {
      default: 5000,
      type: Number,
    },
  },
  data() {
    return {
      active: false,
      transition: "",
    };
  },
  methods: {
    // Deactivate and hide the slide and
    // also activate the correct transition.
    hide(direction) {
      this.transition = `SliderSlide--transition-${direction}`;
      this.active = false;
    },
    // Activate and show the slide and
    // also activate the correct transition.
    show(direction) {
      this.transition = `SliderSlide--transition-${direction}`;
      this.active = true;
    },
  },
};
</script>