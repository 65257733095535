var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$route.query.preview)?_c('b-button',{staticStyle:{"position":"absolute","z-index":"99999","right":"0"},attrs:{"variant":"danger","to":{
      name: 'adminLocationDetail',
      params: { id: _vm.$route.query.locationId },
    }}},[_c('font-awesome-icon',{attrs:{"icon":"window-close"}}),_vm._v(" Sluit preview ")],1):_vm._e(),(!_vm.showSlides && _vm.currentLocation)?_c('b-container',[_c('div',{staticClass:"grid-stack"},[_c('div',{staticClass:"grid-stack-item",attrs:{"gs-no-move":"yes","gs-no-resize":"yes","gs-x":"0","gs-y":"0","gs-w":"4","gs-h":"1"}},[_c('div',{staticClass:"grid-stack-item-content"},[_c('span',{staticClass:"logo"},[_c('img',{attrs:{"src":require("../assets/images/mobiliteitscoach-logo.svg"),"alt":""}}),_c('h1',[_c('strong',[_vm._v("MobiPunt")]),_vm._v(" "+_vm._s(_vm.currentLocation.name))])])])]),(true)?_c('div',{staticClass:"grid-stack-item",attrs:{"gs-x":"0","gs-y":"1","gs-w":"2","gs-h":"11"}},[_c('div',{staticClass:"grid-stack-item-content"},[_c('leiedal-map',{attrs:{"config-json":_vm.currentLocation.mapConfig}})],1)]):_vm._e(),(_vm.currentLocation)?_c('div',{staticClass:"grid-stack-item",attrs:{"gs-no-move":"yes","gs-no-resize":"yes","gs-x":"4","gs-y":"0","gs-w":"1","gs-h":"1"}},[_c('div',{staticClass:"grid-stack-item-content flex-end"},[_c('div',{staticClass:"alt-logo"},[_c('img',{attrs:{"src":require("../assets/images/interreg.png"),"alt":"Interreg logo"}})])])]):_vm._e(),_vm._l((_vm.transportHubsByType),function(transportHubs,hubType){return [(hubType == 'busstop')?_c('div',{key:hubType,staticClass:"grid-stack-item",attrs:{"gs-x":"2","gs-y":"1","gs-w":"3","gs-h":"5"}},[_c('div',{staticClass:"grid-stack-item-content"},[_c('slider-frame',[_c('slider-slides',_vm._l((Math.ceil(
                    transportHubs.length / _vm.stopsPerHubBlock.busstop
                  )),function(i){return _c('slider-slide',{key:i,attrs:{"timeActive":_vm.hubBlockTime}},_vm._l((Math.min(
                      Math.min(
                        transportHubs.length,
                        _vm.stopsPerHubBlock.busstop
                      ),
                      transportHubs.length -
                        (i - 1) * _vm.stopsPerHubBlock.busstop
                    )),function(j){return _c('section',{key:j,staticClass:"time-block lijn",style:('--line-duration:' + _vm.hubBlockTime / 1000 + 's')},[_c('header',[_c('i',{staticClass:"icon-bus"}),_vm._v(" "+_vm._s(transportHubs[ (i - 1) * _vm.stopsPerHubBlock.busstop + j - 1 ].name)+" ")]),(transportHubs[
                      (i - 1) * _vm.stopsPerHubBlock.busstop + j - 1
                      ].lines)?_c('div',{staticClass:"timetable"},[_c('div',{staticClass:"timeslot-legend"},[_c('div',{staticClass:"x1 text-right"},[_vm._v("Vertrek")]),_c('div',{staticClass:"x1"},[_vm._v("NR")]),_c('div',{staticClass:"x7"},[_vm._v("Naar")]),_c('div',{staticClass:"x1 text-right"},[_vm._v("Volgende")])]),_vm._l((transportHubs[
                          (i - 1) * _vm.stopsPerHubBlock.busstop + j - 1
                        ].lines.slice(0, 7)),function(line){return _c('div',{key:line.number,staticClass:"timeslot"},[_c('div',{staticClass:"x1 depart"},[_vm._v(" "+_vm._s(line.departure)+" "),(line.minutesDelayed > 0)?_c('span',{staticClass:"late"},[_vm._v("+"+_vm._s(line.minutesDelayed))]):(line.minutesDelayed < 0)?_c('span',{staticClass:"early"},[_vm._v(_vm._s(line.minutesDelayed))]):_c('span',{staticClass:"on-time"},[_vm._v("op tijd")])]),_c('div',{staticClass:"x1 number"},[_c('span',{class:'bg ' + line.color.achtergrond.code},[_c('b',{class:'text ' + line.color.voorgrond.code},[_vm._v(_vm._s(line.lineNumber))])])]),_c('div',{staticClass:"x7 destination"},[_vm._v(" "+_vm._s(line.destination)+" ")]),_c('div',{staticClass:"x1 text-right next"},[_c('div',{staticClass:"current"},[_vm._v(_vm._s(line.nextDeparture))]),_c('div',{staticClass:"next-one"},[_vm._v(" "+_vm._s(line.nextNextDeparture)+" ")])])])})],2):_vm._e()])}),0)}),1)],1)],1)]):_vm._e(),(hubType == 'treinstation')?_c('div',{key:hubType,staticClass:"grid-stack-item",attrs:{"gs-x":"2","gs-y":"6","gs-w":"2","gs-h":"6"}},[_c('div',{staticClass:"grid-stack-item-content"},[_c('slider-frame',[_c('slider-slides',_vm._l((Math.ceil(
                    transportHubs.length / _vm.stopsPerHubBlock.treinstation
                  )),function(i){return _c('slider-slide',{key:i,attrs:{"timeActive":_vm.hubBlockTime}},_vm._l((Math.min(
                      Math.min(
                        transportHubs.length,
                        _vm.stopsPerHubBlock.treinstation
                      ),
                      transportHubs.length -
                        (i - 1) * _vm.stopsPerHubBlock.treinstation
                    )),function(j){return _c('section',{key:j,staticClass:"time-block nmbs",style:('--line-duration:' + _vm.hubBlockTime / 1000 + 's')},[_c('header',[_c('i',{staticClass:"icon-train"}),_vm._v(" "+_vm._s(transportHubs[ (i - 1) * _vm.stopsPerHubBlock.treinstation + j - 1 ].name)+" ")]),(transportHubs[
                        (i - 1) * _vm.stopsPerHubBlock.treinstation + j - 1
                      ].lines)?_c('div',{staticClass:"timetable"},[_c('div',{staticClass:"timeslot-legend"},[_c('div',{staticClass:"x1 text-right"},[_vm._v("Vertrek")]),_c('div',{staticClass:"x7"},[_vm._v("Naar")]),_c('div',{staticClass:"x1 text-right"},[_vm._v("Volgende")])]),_vm._l((transportHubs[
                          (i - 1) * _vm.stopsPerHubBlock.treinstation + j - 1
                        ].lines.slice(0, 7)),function(line){return _c('div',{key:line.number,staticClass:"timeslot"},[_c('div',{staticClass:"x1 depart"},[_vm._v(" "+_vm._s(line.departure)+" "),(line.minutesDelayed > 0)?_c('span',{staticClass:"late"},[_vm._v("+"+_vm._s(line.minutesDelayed))]):(line.minutesDelayed < 0)?_c('span',{staticClass:"early"},[_vm._v(_vm._s(line.minutesDelayed))]):_c('span',{staticClass:"on-time"},[_vm._v("op tijd")])]),_c('div',{staticClass:"x7 destination"},[_vm._v(" "+_vm._s(line.destination)+" "),(line.termius)?_c('div',{staticClass:"stops"},[_vm._v(" Spoor "+_vm._s(line.termius)+" ")]):_vm._e()]),_c('div',{staticClass:"x1 text-right next"},[_c('div',{staticClass:"current"},[_vm._v(_vm._s(line.nextDeparture))]),_c('div',{staticClass:"next-one"},[_vm._v(" "+_vm._s(line.nextNextDeparture)+" ")])])])})],2):_vm._e()])}),0)}),1)],1)],1)]):_vm._e(),(hubType == 'bluebike')?_c('div',{key:hubType,staticClass:"grid-stack-item",attrs:{"gs-x":"4","gs-y":"6","gs-w":"1","gs-h":"3"}},[_c('div',{staticClass:"grid-stack-item-content"},[_c('slider-frame',[_c('slider-slides',_vm._l((Math.ceil(
                    transportHubs.length / _vm.stopsPerHubBlock.bluebike
                  )),function(i){return _c('slider-slide',{key:i,attrs:{"timeActive":_vm.hubBlockTime}},[_c('section',{staticClass:"time-block share",style:('--line-duration:' + _vm.hubBlockTime / 1000 + 's')},[_c('header',[_c('i',{staticClass:"icon-deelfietsen"}),_vm._v(" Blue Bike ")]),_c('div',{staticClass:"timetable"},_vm._l((Math.min(
                          Math.min(
                            transportHubs.length,
                            _vm.stopsPerHubBlock.bluebike
                          ),
                          transportHubs.length -
                            (i - 1) * _vm.stopsPerHubBlock.bluebike
                        )),function(j){return _c('div',{key:j,staticClass:"timeslot"},[_c('div',{staticClass:"x1"},[_c('span',{staticClass:"quantity"},[_vm._v(" # "+_vm._s(transportHubs[ (i - 1) * _vm.stopsPerHubBlock.bluebike + j - 1 ].bikesAvailable)+" ")]),_vm._v(" "+_vm._s(transportHubs[ (i - 1) * _vm.stopsPerHubBlock.bluebike + j - 1 ].name)+" ")])])}),0)])])}),1)],1)],1)]):_vm._e(),(hubType == 'donkeybike')?_c('div',{key:hubType,staticClass:"grid-stack-item",attrs:{"gs-x":"4","gs-y":"9","gs-w":"1","gs-h":"3"}},[_c('div',{staticClass:"grid-stack-item-content"},[_c('slider-frame',[_c('slider-slides',_vm._l((Math.ceil(
                    transportHubs.length / _vm.stopsPerHubBlock.donkeybike
                  )),function(i){return _c('slider-slide',{key:i,attrs:{"timeActive":_vm.hubBlockTime}},[_c('section',{staticClass:"time-block share",style:('--line-duration:' + _vm.hubBlockTime / 1000 + 's')},[_c('header',[_c('i',{staticClass:"icon-deelfietsen"}),_vm._v(" Donkey Republic ")]),_c('div',{staticClass:"timetable"},_vm._l((Math.min(
                          Math.min(
                            transportHubs.length,
                            _vm.stopsPerHubBlock.donkeybike
                          ),
                          transportHubs.length -
                            (i - 1) * _vm.stopsPerHubBlock.donkeybike
                        )),function(j){return _c('div',{key:j,staticClass:"timeslot"},[_c('div',{staticClass:"x1"},[_c('span',{staticClass:"quantity"},[_vm._v(" # "+_vm._s(transportHubs[ (i - 1) * _vm.stopsPerHubBlock.donkeybike + j - 1 ].bikesAvailable)+" ")]),_vm._v(" "+_vm._s(transportHubs[ (i - 1) * _vm.stopsPerHubBlock.donkeybike + j - 1 ].name)+" ")])])}),0)])])}),1)],1)],1)]):_vm._e()]})],2),_c('div',{staticClass:"ticker-wrap"},[_c('div',{staticClass:"ticker-title"},[_vm._v("Meldingen")]),_c('div',{staticClass:"ticker-list"},[_c('div',{staticClass:"ticker__list"},_vm._l((_vm.currentLocation.notifications),function(notification){return _c('div',{key:notification,staticClass:"ticker__item"},[_vm._v(" "+_vm._s(notification)+" ")])}),0),_c('div',{staticClass:"ticker__list"},_vm._l((_vm.currentLocation.notifications),function(notification){return _c('div',{key:notification,staticClass:"ticker__item"},[_vm._v(" "+_vm._s(notification)+" ")])}),0)]),_c('div',{staticClass:"ticker-info"},[_c('span',{staticClass:"time"},[_vm._v(_vm._s(_vm.currentTime.toLocaleTimeString()))])])])]):(_vm.currentLocation)?_c('b-container',[_c('slider-frame',{on:{"loop-end":_vm.toggleSlideMode}},[_c('slider-slides',{staticClass:"slider-still"},_vm._l((_vm.slides),function(slide){return _c('slider-slide',{key:slide.id,class:slide.templateType,style:({
            'background-color': slide.backgroundColor
              ? slide.backgroundColor
              : '#333333',
            color: '#ffffff',
          }),attrs:{"timeActive":slide.timeActive * 1000}},[_c('div',{staticClass:"text-container"},[_c('div',{staticClass:"text"},[_c('h1',{class:slide.titleAnimation,style:({
                  'text-align': slide.titleAlignment
                    ? slide.titleAlignment
                    : 'center',
                  'font-weight': slide.titleFontWeight,
                  color: slide.titleColor ? slide.titleColor : '#fff',
                  'font-size': slide.titleFontSize
                    ? slide.titleFontSize + 'px'
                    : '30px',
                })},[_vm._v(" "+_vm._s(slide.title)+" ")]),_c('div',{staticClass:"paragraph",class:slide.textAnimation,style:({
                  'text-align': slide.titleAlignment
                    ? slide.titleAlignment
                    : 'center',
                  'font-weight': slide.textFontWeight,
                  color: slide.textColor ? slide.textColor : '#fff',
                  'font-size': slide.textFontSize
                    ? slide.textFontSize + 'px'
                    : '16px',
                })},[_c('div',{domProps:{"innerHTML":_vm._s(slide.text)}})])])]),(slide.image)?_c('div',{staticClass:"float-image"},[_c('img',{staticClass:"image",attrs:{"src":_vm.apiUrl + slide.image.contentUrl}})]):_vm._e()])}),1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }