













































import { IUser, userRepo } from "@/repositories/UserRepository";
import Vue from "vue";

export default Vue.extend({
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data: function () {
    return {
      password: "",
      email: "",
      user: null as IUser | null
    };
  },
  methods: {
    doLogin: function () {
      this.$router.push("adminHome");
    },
  },
  mounted: async function () {
    this.user = await userRepo.getLoggedInUserDetails();
  },
});
