import axios, { AxiosResponse } from 'axios'
import { Vm } from '@/main';

export default class Api {
    //@ts-ignore
    static baseUrl: string = config.ApiUrl + "api/";

    public static async postData(endPoint: string, data: any): Promise<AxiosResponse<any>> {
        const token = localStorage.getItem("token");
        try {
            const res = await axios.post(this.baseUrl + endPoint, data, {
                //AxiosRequestConfig parameter
                headers: {
                    "content-type": "application/json",
                    "Authorization": "Bearer " + token
                }
            });

            if (token)
                res.headers.Authorization = "Bearer " + token

            return res;
        } catch (error: any) {
            console.log(error.response);
            if (error.response.status == 405 || error.response.status == 401) {

                localStorage.removeItem("user");
                Vm.$router.push({ name: "login" });
            } else {
                throw (error);
            }
        }

        return null as unknown as AxiosResponse<any>;
    }

    public static async putData(endPoint: string, data: any): Promise<AxiosResponse<any>> {
        const token = localStorage.getItem("token");
        try {
            const res = await axios.put(this.baseUrl + endPoint, data, {
                //AxiosRequestConfig parameter
                headers: {
                    "content-type": "application/json",
                    "Authorization": "Bearer " + token
                }
            });

            if (token)
                res.headers.Authorization = "Bearer " + token

            return res;
        } catch (error: any) {
            console.log(error.response);
            if (error.response.status == 405 || error.response.status == 401) {

                localStorage.removeItem("user");
                Vm.$router.push({ name: "login" });
            } else {
                throw (error);
            }
        }

        return null as unknown as AxiosResponse<any>;
    }

    public static async getPublicData(endPoint: string): Promise<AxiosResponse<any>> {
        return Api.processGetData(endPoint, false);
    }

    public static async getData(endPoint: string, useToken: boolean = true): Promise<AxiosResponse<any>> {
        return Api.processGetData(endPoint, true);
    }

    private static async processGetData(endPoint: string, useToken: boolean = true): Promise<AxiosResponse<any>> {

        try {
            const headers = {"content-type": "application/json"};
            if (useToken) {
                const token = localStorage.getItem("token");
                // @ts-ignore
                headers["Authorization"] = "Bearer " + token;
            }
            //@ts-ignore
            const url = endPoint.startsWith("/") ?
                //@ts-ignore
                config.ApiHost + endPoint :
                this.baseUrl + endPoint
            const res = await axios.get(url, {
                headers: headers
            });

            return res;
        } catch (error: any) {
            console.log(error.response);
            if (error.response.status == 405 || error.response.status == 401) {
                Vm.$router.push({name: "login"});
            } else {
                throw (error);
            }
        }

        return null as unknown as AxiosResponse<any>;
    }

    public static async deleteData(endPoint: string): Promise<AxiosResponse<any>> {

        try {
            const token = localStorage.getItem("token");
            const res = await axios.delete(this.baseUrl + endPoint, {
               headers: {
                    "content-type": "application/json",
                    "Authorization": "Bearer " + token
                }
            });

            return res;
        } catch (error: any) {
            console.log(error.response);
            if (error.response.status == 405 || error.response.status == 401) {
                Vm.$router.push({ name: "login" });
            } else {
                throw (error);
            }
        }

        return null as unknown as AxiosResponse<any>;
    }
}
