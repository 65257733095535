



























































































































































































































































import Vue from "vue";
import "vue-good-table/dist/vue-good-table.css";
import { ISlide, slideRepo } from "@/repositories/SlideRepository";
//@ts-ignore
import { VueEditor } from "vue2-editor";
//@ts-ignore
import { Chrome } from "vue-color";
//@ts-ignore
import { TheMask } from "vue-the-mask";

import FileUpload from "@/components/FileUpload.vue";
import { required } from "vuelidate/lib/validators";
export default Vue.extend({
  components: {
    VueEditor,
    "color-picker": Chrome,
    FileUpload,
    TheMask,
  },
  data: function () {
    return {
      slideRepo,
      slide: undefined as ISlide | undefined,
      maxStep: 3,
      currentStep: 1,
      customRteToolbar: [
        ["underline"],
        [{ list: "ordered" }, { list: "bullet" }],
      ],
      formattedTimeActive: "",
      editMode: false,
    };
  },
  validations: {
    slide: {
      name: {
        required,
      }
    },
  },
  methods: {
    openModal: async function (slideId: string, locationId : string) {
      this.currentStep = 1
      this.slide = await slideRepo.getSlideById(slideId);

      if (!this.slide) {
        this.slide = {
          id: "",
          name: "",
          title: "",
          titleColor: "#000",
          titleAnimation: "no-animation",
          titleFontSize: 40,
          titleFontWeight: "bold",
          titleAlignment: "center",
          timeActive: 30,
          text: "",
          textColor: "#000",
          textAnimation: "no-animation",
          textFontSize: 16,
          textFontWeight: "normal",
          textAlignment: "center",
          backgroundColor: "#DC2323",
          templateType: "textWithImageRight",
          locationId: locationId,
          //@ts-ignore
          location: locationId
        };
      }

      this.formattedTimeActive = this.milliSecondsToFormattedTime(
        this.slide?.timeActive
      );

      this.$bvModal.show("slide-editor-modal");
    },
    onClickNext: function () {
      this.$v.$touch()
      if (this.$v.$invalid) return;
      this.currentStep++;
    },
    onClickBack: function () {
      this.currentStep = Math.max(this.currentStep - 1, 1);
    },
    saveSlide: async function () {
      if (this.slide) {
        this.slide.timeActive = this.formattedTimeToMilliseconds(
          this.formattedTimeActive
        );
        this.slide.id ?
        await slideRepo.updateSlide(this.slide)
        : await slideRepo.createSlide(this.slide);
      }
      this.$bvModal.hide("slide-editor-modal");

      this.$emit('slide-saved')
    },
    milliSecondsToFormattedTime: function (ms: number | undefined): string {
      if (!ms) return "00:00";

      var minutes = Math.floor(ms / 60);

      var seconds = Math.floor(ms % 60);
      return (
        minutes.toString().padStart(2, "0") +
        ":" +
        seconds.toString().padStart(2, "0")
      );
    },
    formattedTimeToMilliseconds: function (time: string): number {
      var parts = time.split(":");

      if (parts.length != 2) return 0;
      var minutes = parseInt(parts[0]);
      var seconds = parseInt(parts[1]);

      return minutes * 60 + seconds;
    },
  },
  mounted: async function () {},
  computed: {
    progress: function (): number {
      return Math.round(100 / this.maxStep) * this.currentStep;
    },
  },
});
