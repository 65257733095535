















































































































































































































































































































































































































import Vue from "vue";
import { locationRepo, ILocation } from "@/repositories/LocationRepository";
import {
  transportHubRepo,
  ITransportHub,
  ApiTransportHubRepository,
} from "@/repositories/TransportHubRepository";
import { integer, numeric, required } from "vuelidate/lib/validators";
import draggable from "vuedraggable";

//@ts-ignore
import { TheMask } from "vue-the-mask";

export default Vue.extend({
  props: {
    msg: String,
  },
  components: {
    draggable,
    TheMask,
  },
  data: function () {
    return {
      location: {} as ILocation,
      editMode: false,
      transportHubsByType: {
        busstop: [],
        treinstation: [],
        bluebike: [],
        donkeybike: [],
      } as { [type: string]: ITransportHub[] },
      transportHubRepo,
      stopsPerHubBlock: {
        busStop: 1,
        trainStation: 1,
        monkeyBike: 2,
        blueBike: 2,
      },
      timeActiveDashboard: "",
      mounted: false,
      busstopSearch: "",
      busstopsCurrentPage: 0,
      donkeybikeSearch: "",
      donkeybikeCurrentPage: 0,
      trainstationSearch: "",
      trainstationCurrentPage: 0,
      bluebikeSearch: "",
      bluebikeCurrentPage: 0,
      transportHubsLoading: {
        busstop: false,
        treinstation: false,
        bluebike: false,
        donkeybike: false,
      } as { [type: string]: boolean },
      busstopRadius: "",
      bluebikeRadius: "",
      donkeybikeRadius: "",
      treinstationRadius: "",
    };
  },
  validations: {
    busstopRadius: {
      required,
      numeric,
      integer,
    },
    bluebikeRadius: {
      required,
      numeric,
      integer,
    },
    donkeybikeRadius: {
      required,
      numeric,
      integer,
    },
    treinstationRadius: {
      required,
      numeric,
      integer,
    },
    location: {
      name: {
        required,
      },
    },
  },
  methods: {
    saveLocation: async function () {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.location.radius =
        this.busstopRadius +
        "|" +
        this.treinstationRadius +
        "|" +
        this.bluebikeRadius+
        "|" +
        this.donkeybikeRadius;

      if (this.editMode) await locationRepo.updateLocation(this.location);
      else this.location = await locationRepo.createLocation(this.location);

      this.saveHubsPerBlock();
      this.editMode = true;

      localStorage.setItem(
        "timeActiveDashboard",
        this.formattedTimeToMilliseconds(this.timeActiveDashboard) + ""
      );
      this.loadTransportHubs();
      this.$router.replace({
        name: "adminLocationDetail",
        params: { id: this.location.id },
      });
      this.$swal({
        icon: "success",
        toast: true,
        timer: 3000,
        timerProgressBar: true,
        title: "Locatie succesvol opgeslagen",
        showConfirmButton: false,
        position: "top",
      });
    },
    saveHubsPerBlock: function () {
      localStorage.setItem(
        "stopsPerHubBlock",
        JSON.stringify(this.stopsPerHubBlock)
      );
    },
    cancel() {
      this.$router.push({ name: "adminLocationOverview" });
    },
    addNotification: function () {
      this.location.notifications.push("");
    },
    deleteNotification: async function (index: number) {
      var res = await this.$swal({
        title: "Opgelet",
        showCancelButton: true,
        icon: "warning",
        text: "Ben je zeker dat je deze melding wilt verwijderen?",
        confirmButtonColor: "#5945f3",
      });

      if (res.value) this.location.notifications.splice(index, 1);
    },
    defineUniqueUrl: function () {
      console.log(window.location)
      return window.location.origin + this.$router.resolve({
        name: 'Home',
        query: { locationId: this.location.id },
      }).href;
    },
    milliSecondsToFormattedTime: function (ms: number | undefined): string {
      if (!ms) return "00:00";

      var minutes = Math.floor(ms / 60);

      var seconds = Math.floor(ms % 60);
      return (
        minutes.toString().padStart(2, "0") +
        ":" +
        seconds.toString().padStart(2, "0")
      );
    },
    formattedTimeToMilliseconds: function (time: string): number {
      var parts = time.split(":");

      if (parts.length != 2) return 0;
      var minutes = parseInt(parts[0]);
      var seconds = parseInt(parts[1]);

      return minutes * 60 + seconds;
    },
    focus(event: any) {
      event.target.focus();
    },
    onMapConfigChange: function (config: any) {
      if (config.detail) {
        this.location.mapConfig = config.detail[0];
        const mapConfig = JSON.parse(this.location.mapConfig);
        this.location.latitude = mapConfig.initialCenter[0] + "";
        this.location.longitude = mapConfig.initialCenter[1] + "";
      }
    },
    loadTransportHubs: async function () {
      for (const transporthubType of ApiTransportHubRepository.transportationTypes) {
        this.transportHubsLoading[transporthubType] = true;
        transportHubRepo
          .getAllTransportHubsInRadius(
            this.location.longitude,
            this.location.latitude,
            this.$data[transporthubType + "Radius"],
            transporthubType
          )
          .then((res) => {
            this.transportHubsByType[transporthubType] = res;
            this.transportHubsLoading[transporthubType] = false;
            this.$forceUpdate();
          });
      }
    },
  },
  mounted: async function () {
    if (this.$route.params.id != "new") {
      this.location =
        (await locationRepo.getLocationById(this.$route.params.id)) ??
        ({
          id: "",
          mapConfig: "",
          userId: this.$route.query.userId,
        } as ILocation);
      this.editMode = true;

      if (localStorage.getItem("stopsPerHubBlock")) {
        this.stopsPerHubBlock = JSON.parse(
          localStorage.getItem("stopsPerHubBlock")!
        );
      }
    } else {
      this.location = {
        mapConfig: "",
        userId: this.$route.query.userId,
        transporthubs: [],
        slides: [],
        notifications: [],
        stopsPerHubBlock: {
          busStop: 1,
          trainStation: 1,
          monkeyBike: 2,
          blueBike: 2,
        },
      } as unknown as ILocation;
    }

    // init the different radii
    if (this.location.radius && this.location.radius.split("|").length == 4) {
      const radii = this.location.radius.split("|");
      this.busstopRadius = radii[0];
      this.treinstationRadius = radii[1];
      this.bluebikeRadius = radii[2];
      this.donkeybikeRadius = radii[3];
    }

    if (localStorage.getItem("timeActiveDashboard")) {
      this.timeActiveDashboard = this.milliSecondsToFormattedTime(
        parseInt(JSON.parse(localStorage.getItem("timeActiveDashboard")!))
      );
    } else {
      this.timeActiveDashboard = "00:20";
    }

    this.mounted = true;
    if (this.$route.params.id != "new") {
      await this.loadTransportHubs();
    }

    /* await transportHubRepo.getAllTransportHubsInLocationRadius(
      this.location.id,
      "busstop"
    ); */
  },
  computed: {
    filteredBusstops: function (): ITransportHub[] {
      if (!this.transportHubsByType["busstop"]) return [];

      return this.transportHubsByType["busstop"].filter((b) =>
        b.name.toLowerCase().includes(this.busstopSearch.toLowerCase())
      );
    },
    filteredDonkeyBikes: function (): ITransportHub[] {
      if (!this.transportHubsByType["donkeybike"]) return [];

      return this.transportHubsByType["donkeybike"].filter((b) =>
        b.name.toLowerCase().includes(this.donkeybikeSearch.toLowerCase())
      );
    },
    filteredTrainstations: function (): ITransportHub[] {
      if (!this.transportHubsByType["treinstation"]) return [];

      return this.transportHubsByType["treinstation"].filter((b) =>
        b.name.toLowerCase().includes(this.trainstationSearch.toLowerCase())
      );
    },
    filteredblueBikes: function (): ITransportHub[] {
      if (!this.transportHubsByType["bluebike"]) return [];

      return this.transportHubsByType["bluebike"].filter((b) =>
        b.name.toLowerCase().includes(this.bluebikeSearch.toLowerCase())
      );
    },
  },
});
