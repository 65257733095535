





























































import Vue from "vue";
import { locationRepo, ILocation } from "@/repositories/LocationRepository";
import { slideRepo, ISlide } from "@/repositories/SlideRepository";
import SlideEditor from "@/components/admin/SlideEditor.vue";
import draggable from "vuedraggable";

export default Vue.extend({
  props: {
    userId: String,
  },
  components: {
    SlideEditor,
    draggable
  },
  data: function () {
    return {
      columns: [
        {
          label: "",
          field: "id",
          sortable: false,
          width: "20px",
          action: "edit",
        },
        {
          label: "",
          field: "id",
          sortable: false,
          width: "20px",
          action: "delete",
        },
        {
          label: "Naam",
          field: "name",
        },
        {
          label: "Template",
          field: "templateType",
        },
      ],
      location: undefined as ILocation | undefined,
      slides: [] as ISlide[],
      isLoading: false,
      slideRepo: slideRepo,
    };
  },
  methods: {
    editSlide: function (slideId: string) {
      //@ts-ignore
      this.$refs.slideEditor?.openModal(slideId);
    },
    createSlide: function () {
      //@ts-ignore
      this.$refs.slideEditor?.openModal(null, this.location?.id);
    },
    async deleteSlide(id: string) {
      var res = await this.$swal({
        title: "Opgelet",
        showCancelButton: true,
        icon: "warning",
        text: "Ben je zeker dat je deze slide wilt verwijderen?",
        confirmButtonColor: "#5945f3",
      });
      if (res.value) {
        var success = await slideRepo.deleteSlide(id);
        this.loadItems();
        if (success)
          this.$swal({
            icon: "success",
            toast: true,
            timer: 3000,
            timerProgressBar: true,
            title: "Slide succesvol verwijderd",
            showConfirmButton: false,
            position: "top",
          });
      }
    },
    async loadItems() {
      this.isLoading = true;
      try {
        this.slides = await slideRepo.getAllSlidesByLocationId(
          this.location?.id ?? ""
        );
        this.$forceUpdate()
      } catch(e) {
        console.log(e)
      }
      this.isLoading = false;
    },
    orderSlides: async function(){
      await slideRepo.updateSlideOrder(this.location!.id, this.slides)
      this.$swal({
            icon: "success",
            toast: true,
            timer: 3000,
            timerProgressBar: true,
            title: "Volgorde succesvol aangepast",
            showConfirmButton: false,
            position: "top",
          });
    }
  },
  mounted: async function () {
    this.location = await locationRepo.getLocationById(
      this.$route.params.locationId
    );
    await this.loadItems();
  },
});
